import React, { Component } from 'react'
import { Flex } from 'styles/ui.style'
import Icon from 'components/atoms/Icon'
import { localNav as navItems } from 'data'

class LocalNav extends Component {
  render() {
    return (
      <Flex justify="space-around">
        {this.props.navItems.map(({ to, type, text }, i) => (
          <Icon
            key={i}
            margin={{ mr: 2, my: 3 }}
            size={18}
            fontSize={1}
            type={type}
            to={to}
            flexDirection="row">
            {text}
          </Icon>
        ))}
      </Flex>
    )
  }
}

LocalNav.defaultProps = {
  navItems
}

export default LocalNav
