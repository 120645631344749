import React from 'react'
import { Link } from 'components/atoms/Link'
import { P, Container } from 'styles/ui.style'

// 404 stub
const NoMatch = ({ location }) => (
  <Container maxWidth px={3} py={4}>
    <h3>
      Oops, no match found for <code>{location.pathname}</code>
    </h3>
    <P>
      <Link to="/">Back to our homepage</Link>
    </P>
  </Container>
)

export default NoMatch
