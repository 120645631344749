import React, { Component } from 'react'
import { Title, Text, P, Container, Box } from 'styles/ui.style'

import Hero from 'components/molecules/Hero'
import { ButtonBlue } from 'components/atoms/Button'

class Home extends Component {
  render() {
    return (
      <Container>
        <Hero maxWidth bgImage={require('./home-background.jpg')} py={[4, 250]}>
          <Title fontSize={4} mt={0} mb={2} weight={400} color="white">
            Mayku
          </Title>
          <Box width={[1, 1 / 2, 1 / 3]} mb={3}>
            <Text fontSize={3} color="white">
              A Desktop Vacuum Former That Makes Beautiful Things
            </Text>
          </Box>
          <ButtonBlue fontSize={2}>Reserve Yours</ButtonBlue>
        </Hero>
        <Container maxWidth px={3} py={4}>
          <Title fontSize={4}>Mayku Header 1</Title>
          <h2>Mayku Header 2</h2>
          <h3>Mayku Header 3</h3>
          <h4>Mayku Header 4</h4>
          <h5>Mayku Header 5</h5>
          <P>Body</P>
        </Container>
      </Container>
    )
  }
}

export default Home
