import projects from './projects.json'

export function genRecipe() {
  const nchapters = Math.floor(Math.random() * 10) + 1
  let recipe = []
  const alpha = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm'
  ]

  for (let chapter = 0; chapter < nchapters; chapter++) {
    const nsteps = Math.floor(Math.random() * 10) + 1
    let steps = []
    for (let step = 0; step < nsteps; step++) {
      steps.push({
        desc: `${alpha[step]}) Instructions for this step`
      })
    }
    recipe.push({
      title: `Chapter ${chapter}`,
      steps
    })
  }

  return recipe
}

export default projects
