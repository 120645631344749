import React from 'react'
import { Container, Title } from 'styles/ui.style'
import NavProvider from 'components/hoc/NavProvider'

const withLoader = (WrappedComponent, data, withNavigation = false) => {
  const WithLoader = props => {
    const index = data.findIndex(item => item.slug === props.match.params.slug)
    const item = data[index]
    return item ? (
      withNavigation ? (
        <NavProvider
          currentIndex={index}
          data={data}
          component={WrappedComponent}
        />
      ) : (
        <WrappedComponent {...props} {...item} />
      )
    ) : (
      <Container maxWidth>
        <Title>404: Sorry, could not find item {props.match.params.slug}</Title>
      </Container>
    )
  }
  return WithLoader
}

export default withLoader
