import React, { Component } from 'react'
import { NavWrapper, NavItem } from 'components/atoms/Nav'
import Icon from 'components/atoms/Icon'

class FooterNav extends Component {
  baseUrl = 'https://www.mayku.me'
  render() {
    return (
      <NavWrapper
        fontSize={1}
        {...this.props}
        flexDirection={['column', 'row']}>
        <NavItem hideActiveState mr={[0, 4]} py={1} to="/">
          <Icon size={24} type="mayku" />
        </NavItem>
        <NavItem mr={[0, 4]} py={1} to={`${this.baseUrl}/about`}>
          About
        </NavItem>
        <NavItem mr={[0, 4]} py={1} to={`${this.baseUrl}/blog`}>
          Blog
        </NavItem>
        <NavItem mr={[0, 4]} py={1} to={`${this.baseUrl}/privacy-and-terms`}>
          Privacy &amp; Terms
        </NavItem>
        <NavItem mr={[0, 4]} py={1} to={`${this.baseUrl}/join`}>
          Careers
        </NavItem>
        <NavItem mr={[0, 4]} py={1} to={`https://help.mayku.me`}>
          Help
        </NavItem>
      </NavWrapper>
    )
  }
}

export default FooterNav
