import React, { Component } from 'react'
// import Avatar from 'components/atoms/Avatar'
import { NavWrapper, NavItem } from 'components/atoms/Nav'
import { masterNav as navItems } from 'data'

// add a profile link for testing
// navItems.push({
//   to: '/profile/',
//   text: <Avatar size={30} />
// })

export const NavItems = ({ items = navItems }) =>
  items.map(({ to, text }) => (
    <NavItem key={to} ml={4} py={3} to={to}>
      {text}
    </NavItem>
  ))

class MasterNav extends Component {
  render() {
    return (
      <NavWrapper {...this.props}>
        <NavItems />
      </NavWrapper>
    )
  }
}

export default MasterNav
