import React from 'react'
import styled, { css } from 'styled-components'
import { space, fontSize } from 'styled-system'
import isExternal from 'is-url-external'
import { NavLink } from 'react-router-dom'
import { Flex } from 'styles/ui.style'

export const NavWrapper = styled(Flex)`
  align-items: center;
  ${fontSize};
`

const navStyle = css`
  ${space}
  display: inline-block;
  height: 2em;
  text-decoration: none;
  color: ${props => props.theme.colors.text};
  border-bottom: 2px solid ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
`

export const NavItem = styled(
  ({
    mx,
    my,
    px,
    py,
    ml,
    mr,
    mt,
    mb,
    pl,
    pr,
    pt,
    pb,
    hideActiveState,
    ...props
  }) =>
    isExternal(props.to) ? (
      <a href={props.to} {...props}>
        {props.children}
      </a>
    ) : (
      <NavLink {...props}>{props.children}</NavLink>
    )
)`
  ${navStyle}
  ${props =>
    !props.hideActiveState &&
    css`
      &.active {
        color: ${props => props.theme.colors.blue};
        border-bottom-color: ${props => props.theme.colors.blue};
      }
    `};
`
