import React, { Component } from 'react'
import { isTest, isSnapshot } from 'util/env'

// Use ThemeProvider hoc to setup global defaults
import { ThemeProvider } from 'styled-components'

// React Router
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

// Master app theme config
import theme from './styles/theme'

import { Container } from 'styles/ui.style'
import GA from 'components/atoms/GoogleAnalytics'
import Head from 'components/atoms/Meta'
import ZendeskWidget from './components/atoms/ZendeskWidget'

import Header from './components/organisms/Header'
import Footer from './components/organisms/Footer'

import Home from './components/pages/Home'
import Gallery from './components/pages/Gallery'
import Projects, { ProjectItem } from './components/pages/Projects'
import NoMatch from './components/pages/NoMatch'

// Global styles
import { AppWrapper } from './App.style'

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router>
          <AppWrapper>
            <Head />
            <Route path="/" component={Header} />
            <Container>
              <Switch>
                <Redirect exact from="/" to="/projects" />
                {/* <Route path="/home" exact component={Home} /> */}
                {/* <Route path="/gallery" component={Gallery} /> */}
                <Route path="/projects" exact component={Projects} />
                <Route path="/projects/:slug" exact component={ProjectItem} />
                {/* <Route path="/materials" exact component={Projects} /> */}
                {/* <Route path="/materials/:slug" exact component={ProjectItem} /> */}
                <Route component={NoMatch} />
              </Switch>
            </Container>
            <Route path="/" component={Footer} />
            <ZendeskWidget
              manifestKey={process.env.REACT_APP_ZENDESK_WIDGET_MANIFESTKEY}
            />
            {GA.init() && <GA.RouteTracker />}
          </AppWrapper>
        </Router>
      </ThemeProvider>
    )
  }
  componentDidMount() {
    // the app is hidden by default
    // only display if we're in production or development mode
    if (isSnapshot() || isTest()) return false

    document.getElementById('root').removeAttribute('style')
  }
}

export default App
