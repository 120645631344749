import React from 'react'
import { isSnapshot, isTest } from 'util/env'
import { withRouter } from 'react-router'

class ZendeskWidget extends React.Component {
  render() {
    return null
  }

  componentDidUpdate() {
    if (window.zE && typeof window.zE.setHelpCenterSuggestions === 'function')
      window.zE.setHelpCenterSuggestions({ url: true })
  }

  componentDidMount() {
    /* eslint-disable */

    // only display if we're in production or development mode
    if (isSnapshot() || isTest()) return false

    const self = this
    window.addEventListener('load', function(event) {
      // load the widget after all the other assets to improve initial response time
      window.zE ||
        (function(e, t, s) {
          var n = (window.zE = window.zEmbed = function() {
              n._.push(arguments)
            }),
            a = (n.s = e.createElement(t)),
            r = e.getElementsByTagName(t)[0]
          ;(n.set = function(e) {
            n.set._.push(e)
          }),
            (n._ = []),
            (n.set._ = []),
            (a.async = true),
            a.setAttribute('charset', 'utf-8'),
            (a.src =
              'https://static.zdassets.com/ekr/asset_composer.js?key=' + s),
            (n.t = +new Date()),
            (a.type = 'text/javascript'),
            r.parentNode.insertBefore(a, r)
        })(document, 'script', self.props.manifestKey)
    })
  }
}

export default withRouter(ZendeskWidget)
