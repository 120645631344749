import React from 'react'
import styled from 'styled-components'
import { responsiveStyle } from 'styled-system'
import PropTypes from 'prop-types'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import { SlideDownFade, BaseComponent } from 'styles/ui.style'

// not sure why I have to overload this types to remove the warning?
Tab.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

const top = responsiveStyle({
  prop: 'top',
  cssProperty: 'top'
})

const TabBase = styled(BaseComponent(Tabs))`
  position: relative;
  .react-tabs__tab-panel {
    display: none;
  }

  .react-tabs__tab-panel--selected {
    display: block;
  }

  .react-tabs__tab {
    text-transform: capitalize;
  }

  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }

  .react-tabs__tab:focus {
    box-shadow: 0 0 5px hsl(208, 99%, 50%);
    border-color: hsl(208, 99%, 50%);
    outline: none;
  }
  ${top}
`

const TabTabs = styled(TabBase)`
  .react-tabs__tab-list {
    border-bottom: 1px solid ${props => props.theme.colors.border};
    padding: 0;
    text-align: ${props => props.tabAlign};
  }

  .react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 4px 2em;
    cursor: pointer;
  }

  .react-tabs__tab--selected {
    background: #fff;
    border-color: #daddde;
    color: black;
  }
`

const ButtonTabs = styled(TabBase)`
  .react-tabs__tab-list {
    border: none;
    padding: 0;
    text-align: ${props => props.tabAlign};
  }

  .react-tabs__tab {
    display: inline-flex;
    border: 1px solid ${props => props.theme.colors.border};
    border-radius: 50%;
    width: 35px;
    height: 35px;
    text-align: center;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 4px;
    margin: 0 4px;
    margin-bottom: 8px;
    cursor: pointer;
  }

  .react-tabs__tab--selected {
    background: ${props => props.theme.colors.blue};
    border-color: ${props => props.theme.colors.blue};
    color: ${props => props.theme.colors.white};
  }
`

const makeTabs = TabType => {
  const MakeTabs = ({
    nav,
    panels,
    defaultIndex = 0,
    tabAlign = 'left',
    top,
    minHeight
  }) => (
    <TabType defaultIndex={defaultIndex} tabAlign={tabAlign} top={top}>
      <TabList>
        {nav.map((tab, i) => (
          <Tab key={i}>{tab}</Tab>
        ))}
      </TabList>
      {panels.map((panel, i) => (
        <TabPanel key={i} style={{ minHeight }}>
          <SlideDownFade>{panel}</SlideDownFade>
        </TabPanel>
      ))}
    </TabType>
  )
  return MakeTabs
}

export default props => {
  switch (props.type) {
    case 'tab':
      return makeTabs(TabTabs)(props)
    case 'button':
      return makeTabs(ButtonTabs)(props)
    default:
      return makeTabs(TabTabs)(props)
  }
}
