import React from 'react'
import styled, { css } from 'styled-components'
import { space, width, fontSize } from 'styled-system'

const Button = styled.button`
  font: inherit;
  border: none;
  display: inline-block;
  line-height: 1;
  cursor: pointer;

  ${props =>
    props.rounded &&
    css`
      border-radius: 30px;
    `};
  ${props =>
    props.bg &&
    css`
      background-color: ${props.theme.colors[props.bg]};
    `};
  ${props =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `};
  ${props =>
    props.height &&
    css`
      height: ${props.height}px;
    `};
  ${fontSize} ${space} ${width};
`

const ButtonBlue = props => (
  <Button bg="blue" color="white" height={60} px={40} rounded {...props} />
)

export default Button
export { ButtonBlue }
