export const masterNav = [
  {
    to: 'https://www.mayku.me/',
    text: 'FormBox'
  },
  // {
  //   to: 'https://www.mayku.me/blog',
  //   text: 'Blog'
  // },
  // {
  //   to: 'https://www.mayku.me/about',
  //   text: 'About'
  // },
  // {
  //   to: 'https://www.mayku.me/materials',
  //   text: 'Materials'
  // },
  {
    to: 'https://www.mayku.me/store',
    text: 'Store'
  },
  {
    to: 'https://help.mayku.me/',
    text: 'Help'
  },
  {
    to: '/',
    text: 'Projects'
  }
]

export const localNav = [
  // {
  //   to: '/gallery',
  //   type: 'gallery',
  //   text: 'Gallery'
  // },
  {
    to: '/projects',
    type: 'project',
    text: 'Projects'
  }
  // {
  //   to: '/materials',
  //   type: 'material',
  //   text: 'Materials'
  // }
  // {
  //   to: '/techniques',
  //   type: 'technique',
  //   text: 'Techniques'
  // },
  // {
  //   to: '/ask',
  //   type: 'ask',
  //   text: 'Ask'
  // }
]
