import React from 'react'
import { Container } from 'styles/ui.style'

import Carousel from 'components/atoms/Carousel'
import ProjectCard from 'components/molecules/ProjectCard'

import { projects } from 'data'

const Suggestions = Container.extend`
  .carousel-cell {
    margin-right: ${props => (props.theme.space[3] || 16) + 'px'};
  }
`

function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

export default props => (
  <Suggestions {...props} noGutter>
    <Carousel
      slides={shuffle(
        projects.filter(p => p.slug !== props.exclude && p.type === props.type)
      )}
      component={ProjectCard}
      autoPlay={false}
      wrapAround={true}
      pageDots={true}
      cellWidth={['90%', '40%']}
      px={0}
      componentProps={{ width: 1 }}
    />
  </Suggestions>
)
