import React from 'react'
import { Container } from 'styles/ui.style'
import styled, { css } from 'styled-components'
import { space, width } from 'styled-system'

const HeroBackground = styled.div`
  ${props =>
    props.bgImage &&
    css`
      background-image: url(${props => props.bgImage});
      background-size: cover;
      background-position: center top;
    `};
  ${props =>
    props.bgColor &&
    css`
      background-color: ${props => props.bgColor};
    `};
  ${space} ${width};
`

const Hero = ({ children, px = 4, maxWidth, ...props }) => (
  <HeroBackground {...props} px={px}>
    <Container maxWidth={maxWidth}>{children}</Container>
  </HeroBackground>
)

export default Hero
