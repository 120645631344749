import React from 'react'

import { Container } from 'styles/ui.style'

import { ScrollToTopOnMount } from 'components/hoc/ScrollToTop'

import { Head } from 'components/atoms/Meta'

import ProjectCard from 'components/molecules/ProjectCard'

import { ProjectFeatured } from 'components/organisms/Featured'

import { projects } from 'data'

class Projects extends React.Component {
  constructor(props) {
    super(props)
    const type = props.match.path.includes('projects')
      ? 'projects'
      : 'materials'
    const data = projects.filter(p => p.type === type)
    this.state = {
      type,
      data
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const type = nextProps.match.path.includes('projects')
      ? 'projects'
      : 'materials'
    const data = projects.filter(p => p.type === type)
    return {
      type,
      data
    }
  }
  render() {
    const { type } = this.state
    return (
      <Container>
        <Head>
          <title>{type[0].toUpperCase() + type.slice(1)}</title>
        </Head>
        <ProjectFeatured {...this.state.data.find(item => item.featured)} />
        <Container maxWidth noGutter py={4}>
          {this.state.data.map(project => (
            <ProjectCard width={[1, 1 / 2]} key={project.slug} {...project} />
          ))}
        </Container>
        <ScrollToTopOnMount />
      </Container>
    )
  }
}

export default Projects
