import React from 'react'
import { Link } from 'components/atoms/Link'
import { Title, Text, Container, Flex, Box } from 'styles/ui.style'

// molecules
import Hero from 'components/molecules/Hero'
import MakeCureTime from 'components/molecules/MakeCureTime'
// atoms
import { ButtonBlue } from 'components/atoms/Button'
import Video from 'components/atoms/Video'

const ProjectFeatured = ({
  title,
  desc,
  video,
  background,
  makeTime,
  cureTime,
  slug,
  type,
  showVideo
}) => (
  <Hero maxWidth bgImage={background} py={4}>
    <Flex width={1} flexDirection={['column', 'row']} align="center">
      <Box width={[1, 1, 1 / 2]} py={[4, 130]} align={['left', 'left']}>
        <Title fontSize={[4, 5, 6]} mt={0} mb={2} weight="normal">
          {title}
        </Title>
        <Text fontSize={4} width={[1, 7 / 8]}>
          {desc}
        </Text>
        <Container py={3}>
          <Link to={`/${type}/${slug}`}>
            <ButtonBlue fontSize={2}>Make This</ButtonBlue>
          </Link>
        </Container>
        <Container py={4}>
          <MakeCureTime
            makeTime={makeTime}
            cureTime={cureTime}
            flexDirection="row"
            iconMargin={{ mr: 2 }}
            outerMargin={{ mr: 2 }}
          />
        </Container>
      </Box>
      {showVideo && (
        <Box display={['none', 'block']} width={[1, 1, 1 / 2]}>
          {video && (
            <Video
              style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 10px 2px' }}
              {...video}
            />
          )}
        </Box>
      )}
    </Flex>
  </Hero>
)

export default ProjectFeatured
