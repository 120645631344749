import React from 'react'
import logo from './mayku-logo.svg'
import styled from 'styled-components'
import { space, width } from 'styled-system'

const Logo = styled.img`
  display: inline-block;
  ${width};
  ${space};
`

export default props => <Logo {...props} src={logo} />
