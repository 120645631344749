const breakpoints = ['40em', '52em', '64em']

const colors = {
  text: '#282B2C',
  black: '#000',
  grey: '#282C31',
  yellow: '#FFD108',
  orange: '#FF4A00',
  green: '#00B36E',
  blue: '#00C4FF',
  red: '#FA0029',
  white: '#fff',
  background: '#EBEDEF',
  darkBackground: '#EBEDEF',
  border: '#DADDDE',
  sharkGrey: '#A0AFB6',
  lightGrey: '#EFF1F2',
  facebookBlue: '#49639E'
}

// space is used for margin and padding scales
// it's recommended to use powers of two to ensure alignment
// when used in nested elements
// numbers are converted to px
const space = [0, 4, 8, 16, 32, 64, 128, 256, 512]

// typographic scale
const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 96, 128]

// for any scale, either array or objects will work
const lineHeights = [1, 1.125, 1.25, 1.5]

const fontWeights = {
  normal: 400,
  bold: 700
}

const letterSpacings = {
  normal: 'normal',
  caps: '0.25em'
}

// border-radius
const radii = [0, 2, 4, 8]

const borderWidths = [0, 1, 2]

const shadows = [`0 1px 2px 0 ${colors.text}`, `0 1px 4px 0 ${colors.text}`]

const theme = {
  breakpoints,
  colors,
  space,
  fontSizes,
  fonts: {
    body: 'AvantGardeLT-Book, -apple-system, sans-serif',
    headers: 'AvantGardeLT-Medium, -apple-system, sans-serif'
  },
  lineHeights,
  fontWeights,
  maxWidth: 1296,
  letterSpacings,
  radii,
  borderWidths,
  shadows
}

export default theme
