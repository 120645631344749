import React, { Component } from 'react'
import Flickity from 'flickity-imagesloaded'
import 'flickity/css/flickity.css'

import { Container } from 'styles/ui.style'

class Carousel extends Component {
  constructor(props) {
    super(props)
    this.componentProps = this.props.componentProps || {}
  }
  componentDidMount() {
    this.flkty = new Flickity(this.flickity, this.props)
  }
  render() {
    const { component: Component } = this.props
    return (
      <Container>
        <div
          className="main-carousel"
          ref={flickity => (this.flickity = flickity)}>
          {this.props.slides.map((props, i) => (
            <Container
              key={i}
              className="carousel-cell"
              width={this.props.cellWidth}>
              <Component
                px={this.props.px}
                py={this.props.py}
                mx={this.props.mx}
                mb={this.props.mb}
                {...props}
                {...this.componentProps}
              />
            </Container>
          ))}
        </div>
      </Container>
    )
  }
}

Carousel.defaultProps = {
  wrapAround: true,
  contain: true,
  prevNextButtons: false,
  pageDots: false,
  autoPlay: true,
  cellAlign: 'left',
  imagesLoaded: true,
  cellWidth: '80%'
}

export default Carousel
