import React from 'react'
import styled, { css } from 'styled-components'
import { Flex } from 'styles/ui.style'
import { space, width, color, fontSize } from 'styled-system'

import { Container, NavLink } from 'styles/ui.style'

const background = props => {
  const bgi = props.active
    ? require(`./icons/active/${props.iconType}.svg`)
    : require(`./icons/${props.iconType}.svg`)
  return `background: url(${bgi}) center center no-repeat`
}

const Icon = styled.div`
  display: inline-block;
  ${background};
  ${props =>
    props.w &&
    css`
      background-size: contain;
      height: ${props.w}px;
    `};
  ${width};
  ${space};
`

const Label = styled.div`
  text-align: center;
  text-decoration: none;
  ${color};
  ${fontSize};
`

const IconLinkedBlock = props => (
  <NavLink to={props.to}>
    <IconBlock className="icon" {...props} />
    <IconBlock className="icon-active" active {...props} />
  </NavLink>
)

const IconBlock = ({
  type,
  size,
  fontSize,
  className,
  flexDirection = 'column',
  align = 'center',
  children,
  active,
  color,
  title,
  px,
  py,
  p,
  margin = { mx: 2, my: 2 },
  outerMargin,
  style
}) => {
  return (
    <Flex
      p={p}
      px={px}
      py={py}
      title={title}
      {...outerMargin}
      flexDirection={flexDirection}
      align={align}
      className={className}
      style={style}>
      {active ? (
        <Icon w={size} iconType={type} {...margin} active />
      ) : (
        <Icon w={size} iconType={type} {...margin} />
      )}
      {children && (
        <Label fontSize={fontSize} color={color}>
          {children}
        </Label>
      )}
    </Flex>
  )
}

export default props => (
  <Container align="center">
    {props.to ? <IconLinkedBlock {...props} /> : <IconBlock {...props} />}
  </Container>
)
