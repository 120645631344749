import React from 'react'

import withLoader from 'components/hoc/withLoader'

import { Container, Title, Flex, Box, MetaTitle } from 'styles/ui.style'

import ScrollToTop, { ScrollToTopOnMount } from 'components/hoc/ScrollToTop'

import Video from 'components/atoms/Video'
import Icon from 'components/atoms/Icon'
import File from 'components/atoms/File'
import Section from 'components/atoms/Section'
import Tabs from 'components/atoms/Tabs'
import MakeCureTime from 'components/molecules/MakeCureTime'
import Suggestions from 'components/organisms/Suggestions'

import * as Meta from 'components/atoms/Meta'

import { projects } from 'data'

import styled from 'styled-components'

const StyledList = styled.ul`
  margin: 0;
  padding: 0;
`

const StyledItem = styled.li`
  list-style: none;
  margin: 0;
  line-height: 1.6rem;
`

const ItemList = ({ items, title }) => (
  <StyledList>
    {title && <MetaTitle>{title}</MetaTitle>}
    {items.map((item, i) => (
      <StyledItem key={i}>{item.type}</StyledItem>
    ))}
  </StyledList>
)

const ProjectItem = ({
  match,
  title,
  desc,
  thumbnail,
  video,
  makeTime,
  cureTime,
  materials,
  tools,
  recipe,
  slug,
  type,
  files = null,
  gallery = null
}) => {
  const nav = [files && 'files', recipe && 'recipe', gallery && 'gallery']
  const tabs = {
    nav: nav
      .map(
        tab =>
          tab && (
            <Icon px={[0, 2]} py={[0, 1]} type={tab} size={24}>
              {tab}
            </Icon>
          )
      )
      .filter(x => x),
    panels: [
      nav[0] && <Files files={files} />,
      nav[1] && <Recipe materials={materials} tools={tools} recipe={recipe} />,
      nav[2] && <Gallery />
    ].filter(x => x)
  }
  tabs.defaultIndex = nav.filter(x => x).findIndex(t => t === 'recipe')
  return (
    <Container bg="lightGrey">
      <Meta.Head>
        <title>{title}</title>
      </Meta.Head>
      <Meta.Card title={title} desc={desc} image={thumbnail} url={match.url} />

      <Container maxWidth width={[1, '60%']} py={0}>
        {video && <Video {...video} />}
      </Container>
      <Section bg="white">
        <Container>
          <Title mt={2} mb={3} fontSize={5}>
            {title}
          </Title>
          <MakeCureTime
            iconMargin={{ mr: 2 }}
            outerMargin={{ mr: 2 }}
            makeTime={makeTime}
            cureTime={cureTime}
            flexDirection="row"
          />
        </Container>
        <Container>
          <Tabs
            type="tab"
            top={['0px', '-82px']}
            minHeight="300px"
            tabAlign="right"
            {...tabs}
          />
        </Container>
      </Section>
      {/* <Section title="What makers say" bg="lightGrey" /> */}
      <Section title="What to make next" bg="white">
        <Suggestions exclude={slug} type={type} />
      </Section>
      <ScrollToTop />
      <ScrollToTopOnMount />
    </Container>
  )
}

const Files = ({ files }) => (
  <Container>
    {files.map(file => (
      <File key={file.href} {...file} />
    ))}
  </Container>
)
const Gallery = () => <Container />

const Recipe = ({ materials, tools, recipe }) => (
  <Container>
    <Flex flexDirection={['column', 'row']}>
      <Box width={[1, 1 / 4]} mb={3}>
        <h2>You'll Need</h2>
        <Flex justifyContent="space-between">
          <Box flex="1 1 0" mr={[0, 2]}>
            <ItemList items={materials} title="materials" />
          </Box>
          <Box flex="1 1 0">
            <ItemList items={tools} title="tools" />
          </Box>
        </Flex>
      </Box>
      <Box flex="1 1">
        <h2 style={{ float: 'left' }}>Steps</h2>
        <Container mt={3}>
          <Steps steps={recipe} />
        </Container>
      </Box>
    </Flex>
  </Container>
)

const Steps = ({ steps }) => {
  const tabs = steps.reduce(
    (all, item, index) => {
      all.nav.push(index + 1)
      all.panels.push(<StepsPanel key={index} steps={item.steps} />)
      return all
    },
    { nav: [], panels: [] }
  )
  return <Tabs type="button" tabAlign="right" {...tabs} />
}

const StepsPanel = ({ steps }) =>
  steps.map(({ desc }, i) => (
    <Container key={i} bg="lightGrey" p={4} m={2}>
      {desc}
    </Container>
  ))

export default withLoader(ProjectItem, projects)
