import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Flex } from 'styles/ui.style'
import styled from 'styled-components'

const NavItem = styled.div`
  display: block;
  cursor: pointer;
  flex-grow: 1;
  text-align: ${props => props.align};
`

class Navigation extends Component {
  constructor(props) {
    super(props)

    this.onKeyDown = this.handleKeyDown.bind(this)
    this.state = {
      index: props.currentIndex || 0,
      data: props.data,
      match: props.match
    }
  }
  nextItem = () => {
    this.setState({ index: this.state.index + 1 })
  }
  prevItem = () => {
    this.setState({ index: this.state.index - 1 })
  }

  getItem(index) {
    return this.state.data[index]
  }

  currentItem() {
    return this.state.data[this.state.index]
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.index !== this.state.index) {
      this.props.history.replace(this.currentItem().slug)
    }
  }

  hasNext() {
    return !!this.state.data[this.state.index + 1]
  }

  hasPrev() {
    return !!this.state.data[this.state.index - 1]
  }

  handleKeyDown = event => {
    switch (event.keyCode) {
      case 37:
        this.hasPrev() && this.prevItem()
        break
      case 39:
        this.hasNext() && this.nextItem()
        break
      default:
        break
    }
  }

  componentDidMount() {
    // setup key listener
    document.addEventListener('keydown', this.onKeyDown)
  }

  componentWillUnmount() {
    // cleanup key listener
    document.removeEventListener('keydown', this.onKeyDown)
  }

  render() {
    const item = this.currentItem()
    const match = this.state.match
    const hasNext = this.hasNext()
    const hasPrev = this.hasPrev()
    const Item = this.props.component
    return [
      <Item key="item" {...item} match={match} />,
      <Flex key="nav" justify="space-between" align="center">
        {hasPrev && (
          <NavItem align="left" onClick={this.prevItem}>
            Prev
          </NavItem>
        )}
        {hasNext && (
          <NavItem align="right" onClick={this.nextItem}>
            Next
          </NavItem>
        )}
      </Flex>
    ]
  }
}

const NavProvider = withRouter(Navigation)

export default NavProvider
