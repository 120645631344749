import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import isExternal from 'is-url-external'

/**
 * Link that also works for external URL's
 */
export class LinkDuo extends Component {
  render() {
    return isExternal(this.props.to) ? (
      <a href={this.props.to} {...this.props}>
        {this.props.children}
      </a>
    ) : (
      <Link {...this.props}>{this.props.children}</Link>
    )
  }
}

LinkDuo.propTypes = {
  to: PropTypes.string.isRequired
}
