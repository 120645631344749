import include from '../loader'

const sample = [
  {
    image: include('/assets/gallery/candles.jpg'),
    user: 'Team Mayku',
    title: 'Candles',
    likes: 7,
    desc:
      'Designed with Brutalism in mind, we cut and sanded wooden templates to form the mold for these candles. If you want to work with wax, you can dye clear or transparent wax to your desired shade, or there are plenty of coloured waxes available.',
    featured: true
  },
  {
    image: include('/assets/gallery/chocolate-shells.jpg'),
    user: 'Team Mayku',
    title: 'Chocolate Shells',
    likes: 13,
    desc:
      'We used shells right from the beach to make a tasty gift. Flavours and colors can come in handy here too… how about sea salt choco-shells, or a white and milk chocolate chess set?',
    featured: true
  },
  {
    image: include('/assets/gallery/clock.jpg'),
    user: 'Team Mayku',
    title: 'Clock',
    desc:
      'For another concrete project, we laser-cut wood to make our simple clock-shape template. After forming plastic around it, we popped a straw in the center of the mold to make room for the clock’s hands and added the concrete.',
    likes: 7
  },
  {
    image: include('/assets/gallery/coaster.jpg'),
    user: 'Team Mayku',
    title: 'Coasters',
    desc:
      'The template for these coasters was another 3D printing venture, although we’ve also used digestive biscuits as templates in the past! After 3D printing our coaster shape and forming it in plastic, we used Jesmonite (a Maker favorite) to fill the mold.',
    likes: 7
  },
  {
    image: include('/assets/gallery/dome-planter.jpg'),
    user: 'Team Mayku',
    title: 'Dome Planter',
    desc:
      'This planter was made by mixing two shades of concrete, producing the amazing colour-change effect. Techniques like this will give you makes high-end finishes, looking right at home on great furniture or in a storefront.',
    likes: 13
  },
  {
    image: include('/assets/gallery/lightball.jpg'),
    user: 'Team Mayku',
    title: 'Light Balls',
    desc:
      'This glowing ball of light was made by forming two plastic shells and putting them together in a two-part molding process. We love the results - and any household bowl can be used to make one!',
    likes: 13
  },
  {
    image: include('/assets/gallery/magnetic-paperclip-tower.jpg'),
    user: 'Team Mayku',
    title: 'Magnetic Tower',
    desc:
      'Our paperclip tower used a 3D print as its template, forming a plastic mold around the object. Into the mold, we poured plaster and dropped a little magnet. Now, any metal stationery will stick right to the side!',
    likes: 7
  },
  {
    image: include('/assets/gallery/music-crystal.jpg'),
    user: 'Team Mayku',
    title: 'Music Crystal',
    likes: 7,
    desc:
      'Using resin as a casting material opens up a world of possibilities with electronics. This music crystal was made by using polyester resin and Arduino parts. Copper wire adds a sculptural sense of dimension.',
    featured: true
  },
  {
    image: include('/assets/gallery/pyramid-soaps.jpg'),
    user: 'Team Mayku',
    title: 'Pyramid Soaps',
    likes: 13,
    desc:
      'You’d never guess these gift-worthy soaps started life as a potato! Go wild with fragrances, scrubs and essential oils, and add some sparkle with a little bit of gold leaf or glitter.',
    featured: true
  },
  {
    image: include('/assets/gallery/soap-gems.jpg'),
    user: 'Team Mayku',
    title: 'Soap Gems',
    likes: 13,
    desc:
      'Here, we used wooden templates to form our molds, which were then filled with glycerin soap. You could try molding any material that gets you squeaky clean and smelling tip-top.',
    featured: true
  },
  {
    image: include('/assets/gallery/terrarium.jpg'),
    user: 'Team Mayku',
    title: 'Terrarium',
    likes: 13,
    desc:
      'We’ve made tons of terrariums by forming the same clay object to make the plastic shell and mold the concrete base. The model inside the formed shell is also clay, with sand and paints added to texturize.',
    featured: true
  },
  {
    image: include('/assets/gallery/watercolour-book-cover.jpg'),
    user: 'Team Mayku',
    title: 'Watercolor Book',
    desc:
      'We formed a watercolor organizer around a paintbox, brush, and water well as templates, attaching the formed plastic to a notebook and securing with a red elastic band. This project works to hold together any notebook and items you want to keep at hand.',
    likes: 13,
    featured: true
  }
]

export default sample
