import React from 'react'
import { isSnapshot, isTest } from 'util/env'
import videojs from 'video.js'
import './video-js.css'

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    // instantiate Video.js

    // only display if we're in production or development mode
    if (isSnapshot() || isTest()) return false

    this.setupPlayer()
  }
  setupPlayer() {
    this.setState({
      player: videojs(this.videoNode, this.props, function onPlayerReady() {
        // something magical...
      })
    })
  }
  setVideoPlayerSrc(src) {
    this.state.player.src(src)
  }
  setVideoPlayerPoster(src) {
    this.state.player.poster(src)
  }
  restartVideo() {
    this.state.player.currentTime(0)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.player) {
      let currentSrc = prevProps.poster
      let newSrc = this.props.poster

      if (currentSrc !== newSrc) {
        this.setVideoPlayerSrc(this.props.sources)
        this.setVideoPlayerPoster(this.props.poster)
        // this.restartVideo()
      }
    }
  }
  // destroy player on unmount
  componentWillUnmount() {
    if (this.state.player) {
      this.state.player.dispose()
    }
  }
  render() {
    return (
      <div data-vjs-player style={this.props.style}>
        <video
          ref={node => (this.videoNode = node)}
          className="video-js vjs-big-play-centered"
        />
      </div>
    )
  }
}

VideoPlayer.defaultProps = {
  fluid: true,
  loop: false,
  autoplay: false,
  controls: false,
  aspectRatio: '16:9',
  playbackRates: [1, 1.5, 2],
  controlBar: {
    fullscreenControl: false
  }
}

export default VideoPlayer
