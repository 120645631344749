import React from 'react'
import { Flex } from 'styles/ui.style'
import Icon from 'components/atoms/Icon'

const formatTime = mins => {
  let h, m
  if (mins > 60) {
    h = Math.floor(mins / 60)
    m = mins - 60 * h
    if (m > 0) {
      return `${h} h ${m} min`
    } else {
      return `${h} hrs`
    }
  } else {
    return `${mins} min`
  }
}

const MakeCureTime = ({
  makeTime,
  cureTime,
  flexDirection = 'column',
  align = 'left',
  size = 24,
  iconMargin,
  outerMargin
}) => {
  return (
    <Flex align={align} flexDirection={flexDirection}>
      <Icon
        size={size}
        margin={iconMargin}
        outerMargin={outerMargin}
        type="maketime"
        flexDirection="row"
        title="Make Time"
        active>
        {formatTime(makeTime)}
      </Icon>
      <Icon
        size={size}
        margin={iconMargin}
        outerMargin={outerMargin}
        type="curetime"
        flexDirection="row"
        title="Cure Time"
        active>
        {formatTime(cureTime)}
      </Icon>
    </Flex>
  )
}

export default MakeCureTime
