import React, { Component } from 'react'

import { Container } from 'styles/ui.style'

import FooterNav from 'components/molecules/FooterNav'
import SocialNav from 'components/molecules/SocialNav'
import { responsiveStyle } from 'styled-system'

const FooterWrapper = Container.extend`
  display: flex;
  justify-content: space-between;
  ${responsiveStyle('flex-direction', 'direction')};
  background-color: ${props => props.theme.colors.white};
`

class Footer extends Component {
  render() {
    return (
      <FooterWrapper
        maxWidth
        noGutter
        p={3}
        mb={[2, 5]}
        flexDirection={['column', 'row']}>
        <FooterNav />
        <SocialNav />
      </FooterWrapper>
    )
  }
}

export default Footer
