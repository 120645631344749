import slugg from 'slugg'

import projects from './projects'
import gallery from './gallery'

import include from './loader'

export { localNav, masterNav } from './nav'
export { app } from './app'

// compute slugs from project titles
const applySlugs = items =>
  items.map(item => Object.assign(item, { slug: slugg(item.title) }))

// TODO: hacky manual transform to load from CDN
// make this automatic
const applyLoader = items =>
  items.map(item => {
    item.thumbnail = include(item.thumbnail)
    item.background = include(item.background)
    if (item.video) {
      item.video.poster = include(item.video.poster)
      item.video.sources = item.video.sources.map(source => {
        source.src = include(source.src)
        return source
      })
    }
    if (item.files) {
      item.files = item.files.map(file => {
        file.href = include(file.href)
        return file
      })
    }
    return item
  })

const galleryWithSlugs = applySlugs(gallery)
const projectsWithSlugs = applyLoader(applySlugs(projects))

export default projects
export { galleryWithSlugs as gallery, projectsWithSlugs as projects }
