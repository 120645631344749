import React from 'react'
import { Container } from 'styles/ui.style'
import styled from 'styled-components'
import path from 'path'

const File = Container.extend`
  display: inline-block;
  width: 150px;
  margin-right: ${props => props.theme.space[4] + 'px'};
  text-align: center;
  a {
    text-decoration: none;
    color: ${props => props.theme.colors.text};
    &:hover {
      color: ${props => props.theme.colors.blue};
    }
  }
`

const Img = styled.img`
  width: 100%;
`

const Filename = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
`

export default props => {
  const type = props.href && props.href.split('.').pop()
  const filename = path.basename(props.href, path.extname(props.href))
  let icon
  try {
    icon = require(`./icons/${type}.svg`)
  } catch (e) {
    icon = require(`./icons/unknown.svg`)
  }
  return (
    <File {...props}>
      <a href={props.href} download>
        <Img src={icon} />
        <Filename>{filename}</Filename>
      </a>
    </File>
  )
}
