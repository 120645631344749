import React, { Component } from 'react'
import {
  Container,
  Flex,
  Box,
  Hide,
  slideDownFadeKeyframes
} from 'styles/ui.style'
import { localNav, masterNav } from 'data'
import Icon from 'components/atoms/Icon'
import { Link } from 'components/atoms/Link'

import { color } from 'styled-system'

import Logo from 'components/atoms/MaykuLogo'
import Hamburgler from 'components/atoms/Hamburgler'
import { NavItem } from 'components/atoms/Nav'
import MasterNav from 'components/molecules/MasterNav'
import LocalNav from 'components/molecules/LocalNav'

const UpperNav = Container.extend`
  border-bottom: 1px solid;
  border-color: ${props => props.theme.colors.lightGrey};
  ${color};
`

const LowerNav = Container.extend`
  display: ${props => (props.visible ? 'block' : 'none')};
  position: relative;
  animation: ${slideDownFadeKeyframes} 1s cubic-bezier(0.4, 0, 0.2, 1) 1s both;
  ${color};
`

const MainHeader = Container.extend`
  position: -webkit-sticky;
  position: sticky;
  top: -66px;
  z-index: 999;
`

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: true
    }
  }
  showLowerNav = () => {
    this.setState({ visible: true })
  }
  hideLowerNav = () => {
    this.setState({ visible: false })
  }
  render() {
    return (
      <MainHeader>
        <Hide xs>
          <UpperNav px={3} bg="white">
            <Container maxWidth fontSize={2}>
              <Flex align="center">
                <Box flex="1 1 auto">
                  <NavItem
                    hideActiveState={true}
                    mx={0}
                    py={2}
                    to="https://www.mayku.me/">
                    <Logo height="20px" />
                  </NavItem>
                </Box>
                <Box flex="0 1 auto">
                  <MasterNav fontSize={'15px'} />
                </Box>
              </Flex>
            </Container>
          </UpperNav>
          {/* <Container bg="white">
            <LowerNav
              visible={this.state.visible}
              mw="1000"
              fontSize={'15px'}
              m="auto"
              px={3}
              py={2}>
              <LocalNav />
            </LowerNav>
          </Container> */}
        </Hide>
        <Hide sm md lg>
          <UpperNav px={3} py={2} bg="white">
            <Container maxWidth fontSize={2}>
              <Flex align="center">
                <Box flex="1 1 auto">
                  <NavItem
                    hideActiveState={true}
                    mx={0}
                    py={2}
                    to="https://www.mayku.me/">
                    <Logo height="20px" />
                  </NavItem>
                </Box>
                <Box flex="0 1 auto">
                  <Hamburgler>
                    <Container p={4} className="container">
                      <Flex justify="space-around" flexDirection="column">
                        <Icon
                          style={{ float: 'left' }}
                          margin={{ mb: 4 }}
                          size={28}
                          type="mayku-white"
                        />
                        <h2>Make</h2>
                        {localNav.map(({ to, text, type }) => (
                          <Icon
                            key={to}
                            size={26}
                            fontSize={3}
                            color="white"
                            type={type}
                            flexDirection="row"
                            margin={{ mr: 3 }}
                            py={2}
                            to={to}>
                            {text}
                          </Icon>
                        ))}
                        <h2>Mayku</h2>
                        {masterNav
                          .filter(l => l.to !== '/')
                          .map(({ to, text, type }) => (
                            <Link to={to} key={to}>
                              {text}
                            </Link>
                          ))}
                      </Flex>
                    </Container>
                  </Hamburgler>
                </Box>
              </Flex>
            </Container>
          </UpperNav>
        </Hide>
      </MainHeader>
    )
  }
}

export default Header
