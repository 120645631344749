import React from 'react'
import ReactModal from 'react-modal'

export default class Modal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modalIsOpen: this.props.isOpen || false
    }

    this.openModal = this.openModal.bind(this)
    this.afterOpenModal = this.afterOpenModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.customStyles = this.props.customStyles || {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        maxHeight: '90vh',
        marginRight: '-40%',
        transform: 'translate(-50%, -50%)',
        maxWidth: this.props.maxWidth || '40rem',
        borderRadius: 'none',
        borderColor: '#EFF1F2'
      },
      overlay: {
        zIndex: 9999
      }
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true })
  }

  afterOpenModal() {}

  closeModal() {
    this.setState({ modalIsOpen: false })
    const { history, returnPath } = this.props

    if (history && returnPath) {
      history.push(returnPath)
    }
  }

  componentWillMount() {
    ReactModal.setAppElement('body')

    // cleanup any ssr rendered elements
    // TODO: this shouldn't be necessary is hydrate broken?
    const ssr = document.querySelector('.ReactModalPortal')
    if (ssr) ssr.remove()
  }

  render() {
    return (
      <ReactModal
        isOpen={this.state.modalIsOpen}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.closeModal}
        style={this.customStyles}
        contentLabel="Gallery Item">
        {this.props.children}
      </ReactModal>
    )
  }
}
