import styled, { keyframes } from 'styled-components'

const fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const AppWrapper = styled.main`
  animation: ${fade} 1s ease;
`

export const Intro = styled.p`
  font-size: large;
`
