import React from 'react'
import styled from 'styled-components'
import { Flex, Box, Title, P, Container } from 'styles/ui.style'

import Ratio169 from 'components/atoms/Ratio169'
import Video from 'components/atoms/Video'
import MakeCureTime from 'components/molecules/MakeCureTime'

const HoverFlex = styled(Flex)`
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.2s ease;
  box-sizing: border-box;
  &:hover {
    opacity: 1;
  }
`

const CardTitle = Title.withComponent('h2')

const CardMeta = ({ title, desc, makeTime, cureTime, display, style }) => (
  <Container display={display} style={style}>
    <CardTitle mb={0} weight="300">
      {title}
    </CardTitle>
    <P style={{ flexGrow: 1 }}>{desc}</P>
    <MakeCureTime
      makeTime={makeTime}
      cureTime={cureTime}
      iconMargin={{ mr: 3, my: 1 }}
      outerMargin={{ mr: 2 }}
      flexDirection={['row', 'column']}
    />
  </Container>
)

const ProjectCard = ({
  title,
  desc,
  video,
  width,
  thumbnail,
  makeTime,
  cureTime,
  slug,
  showVideo,
  type,
  px,
  mx,
  my
}) => (
  <Ratio169
    width={width}
    thumbnail={thumbnail}
    px={px}
    mx={mx}
    my={my}
    to={`/${type}/${slug}`}>
    <HoverFlex
      px={3}
      pb={3}
      flexDirection={['column', 'row']}
      style={{ height: '100%' }}>
      <Box width={[1, 1 / 2]} color="white">
        <CardMeta
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          title={title}
          desc={desc}
          makeTime={makeTime}
          cureTime={cureTime}
        />
      </Box>
      {showVideo && (
        <Box
          display={['none', 'block']}
          width={[1, 1 / 2]}
          alignSelf="flex-end">
          {video && (
            <Video {...video} autoplay={true} loop={true} controls={false} />
          )}
        </Box>
      )}
    </HoverFlex>
  </Ratio169>
)

export default ProjectCard
