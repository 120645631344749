import { theme, headers } from './styles/ui.style.js'
import { injectGlobal } from 'styled-components'
import { fontFace, normalize } from 'polished'

// Inject global styles
// use this once per app and sparingly

// eslint-disable-next-line
injectGlobal`
  ${normalize()}
  ${fontFace({
    fontFamily: 'AvantGardeLT-Medium',
    fontFilePath: '/fonts/AvantGardeLT-Medium'
  })}
  ${fontFace({
    fontFamily: 'AvantGardeLT-Book',
    fontFilePath: '/fonts/AvantGardeLT-Book'
  })}
  html {
    line-height: ${theme.lineHeights[2]};
    font-family: ${theme.fonts.body};
    color: ${theme.colors.text};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  ${headers(['h5', 'h4', 'h3', 'h2', 'h1', '.hxl'])}
`
