import React from 'react'
import styled from 'styled-components'
import { Link } from 'components/atoms/Link'
import { space, width, fontSize } from 'styled-system'
import { Container, MetaTitle } from 'styles/ui.style'

// import LikeCount from 'components/molecules/LikeCount'

const GalleryItem = styled.div`
  display: ${props => (props.solo ? 'block' : 'inline-block')};
  box-sizing: border-box;
  ${props => props.centered && 'margin: 0 auto;'}
  ${space}
  ${width}
`

const GalleryImage = styled.img`
  display: block;
  width: 100%;
  ${space}
  ${width}
`

const GalleryTitle = styled.p`
  ${space}
  ${width}
  ${fontSize}
`

const GalleryCard = ({
  slug,
  width,
  image,
  title,
  likes,
  user,
  centered,
  solo,
  px,
  py = 3,
  mx,
  mb
}) => (
  <GalleryItem centered={centered} solo={solo} width={width} px={mx} pb={mb}>
    {slug ? (
      <Link to={`/gallery/${slug}`}>
        <GalleryImage src={image} />
      </Link>
    ) : (
      <GalleryImage src={image} />
    )}

    <Container bg="white" px={px} py={py}>
      <MetaTitle mb={1} mt={0}>
        {user}
      </MetaTitle>

      <GalleryTitle m={0} fontSize={22}>
        {title}
      </GalleryTitle>

      {/* <LikeCount count={likes} /> */}
    </Container>
  </GalleryItem>
)

export default GalleryCard
