export default item => {
  switch (process.env.NODE_ENV) {
    case 'development':
    case 'test':
      try {
        return item
      } catch (e) {
        throw new Error('Could not find asset', e)
      }
    case 'production':
      return process.env.REACT_APP_CDN_URL + item
    default:
      return item
  }
}
