import React from 'react'
import { withRouter } from 'react-router-dom'
import SweetScroll from 'sweet-scroll'

class ScrollToTop extends React.Component {
  constructor(props) {
    super(props)
    this.scroller = new SweetScroll()
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.scroller.to(0, 0)
    }
  }

  render() {
    return this.props.children || null
  }
}

export class ScrollToTopOnMount extends React.Component {
  constructor(props) {
    super(props)
    this.scroller = new SweetScroll()
  }
  componentDidMount(prevProps) {
    this.scroller.to(0, 0)
  }

  render() {
    return null
  }
}

export default withRouter(ScrollToTop)
