import React from 'react'
import { Container, P } from 'styles/ui.style'

import withLoader from 'components/hoc/withLoader'

import * as Meta from 'components/atoms/Meta'
import GalleryCard from 'components/molecules/GalleryCard'

// import { ButtonBlue } from 'components/atoms/Button'

import { gallery } from 'data'

const GalleryItem = galleryItem => (
  <Container width={1}>
    <Meta.Head>
      <title>{galleryItem.title}</title>
    </Meta.Head>
    <Meta.Card
      title={galleryItem.title}
      desc={galleryItem.desc}
      image={galleryItem.image}
      url={galleryItem.match.url}
    />
    <GalleryCard mb={0} centered solo width={1} {...galleryItem} />
    {/* TODO: Comments block */}
    <DescriptionBlock desc={galleryItem.desc} />
    {/* <CommentBlock /> */}
  </Container>
)

const DescriptionBlock = ({ desc }) => (
  <Container align="left">{desc && <P>{desc}</P>}</Container>
)

// const CommentBlock = props => (
//   <Container align="center">
//     <P>Comments</P>
//     <ButtonBlue fontSize={1}>Post a Comment</ButtonBlue>
//   </Container>
// )

export default withLoader(GalleryItem, gallery, true)
