import React from 'react'
import Helmet from 'react-helmet'
import { app } from 'data'

export default ({ title }) => (
  <Helmet htmlAttributes={{ lang: 'en' }}>
    <meta charset="utf-8" />
    {title && <title>{title}</title>}
    <meta name="theme-color" content="#000000" />
    {/* <meta name="keywords" content="" /> */}
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    {app.descMeta && <meta name="description" content={app.descMeta} />}
  </Helmet>
)

export const Card = ({ desc, title, image, url }) => {
  const metaTitle = title ? `${title} | ${app.title}` : app.title
  const metaUrl = app.homepage + url
  const metaDesc = desc || app.descMeta
  const metaTwitter = app.twitter
  return (
    <Helmet>
      <meta name="twitter:card" content="summary_large_image" />
      {metaTwitter && <meta name="twitter:site" content={metaTwitter} />}
      {metaTitle && <meta name="twitter:title" content={metaTitle} />}
      {metaDesc && <meta name="twitter:description" content={metaDesc} />}
      {image && <meta name="twitter:image:src" content={image} />}
      <meta property="og:type" content="website" />
      {metaTitle && <meta property="og:title" content={metaTitle} />}
      {metaDesc && <meta property="og:description" content={metaDesc} />}
      {image && <meta property="og:image" content={image} />}
      {url && <meta property="og:url" content={metaUrl} />}
    </Helmet>
  )
}

export const Head = ({ children }) => (
  <Helmet titleTemplate={`%s | ${app.title}`}>{children}</Helmet>
)
