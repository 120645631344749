import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'components/atoms/Link'
import { Container } from 'styles/ui.style'
import { space } from 'styled-system'

const CardWrapper = Container.extend`
  display: inline-block;
  box-sizing: border-box;
`

const Card = styled.div`
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  ${props =>
    props.bgImage &&
    css`
      background-image: url(${props => props.bgImage});
      background-size: cover;
      background-position: right top;
    `};
  ${props =>
    props.bgColor &&
    css`
      background-color: ${props => props.bgColor};
    `};
`

const CardInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  ${space};
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const withLink = WrappedComponent => {
  const WithLink = props => (
    <StyledLink to={props.to}>
      <WrappedComponent {...props} />
    </StyledLink>
  )
  return WithLink
}

const Ratio169 = ({ children, width, thumbnail, px = 3, pb = 3, mx, my }) => (
  <CardWrapper width={width} px={px} pb={pb} mx={mx} my={my}>
    <Card bgImage={thumbnail}>
      <CardInner>{children}</CardInner>
    </Card>
  </CardWrapper>
)

export default props => (props.to ? withLink(Ratio169)(props) : Ratio169(props))
