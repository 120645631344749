import React from 'react'
import { Container } from 'styles/ui.style'

const Section = Container.withComponent('section')

export default props => (
  <Section bg={props.bg}>
    <Container mx={props.nested ? 0 : 3}>
      <Container py={3} maxWidth {...props}>
        {props.title && <h2>{props.title}</h2>}
        {props.children}
      </Container>
    </Container>
  </Section>
)
