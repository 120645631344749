import React, { Component } from 'react'
import { NavWrapper, NavItem } from 'components/atoms/Nav'
import Icon from 'components/atoms/Icon'

class SocialNav extends Component {
  render() {
    return (
      <NavWrapper fontSize={1} {...this.props} flexDirection={['row', 'row']}>
        <NavItem ml={[1, 4]} py={1} to="https://www.instagram.com/teammayku/">
          <Icon size={24} type="instagram" />
        </NavItem>
        <NavItem ml={[1, 4]} py={1} to="https://www.facebook.com/TeamMayku/">
          <Icon size={24} type="facebook" />
        </NavItem>
        <NavItem ml={[1, 4]} py={1} to="https://twitter.com/teammayku">
          <Icon size={24} type="twitter" />
        </NavItem>
      </NavWrapper>
    )
  }
}

export default SocialNav
