import styled, { keyframes } from 'styled-components'
import { NavLink as NavigationLink } from 'react-router-dom'
import {
  space,
  width,
  fontSize,
  color,
  textAlign,
  responsiveStyle,
  alignSelf,
  flex,
  justifyContent,
  alignItems,
  flexWrap,
  flexDirection,
  propTypes
} from 'styled-system'
import cleanElement from 'clean-element'
import customPropsTypes from './proptypes'
import theme from './theme'

// use a named export for all the theme vars
export { theme }

export const BaseComponent = (Component = 'div') => {
  const CleanEl = cleanElement(Component)

  // construct all system prop-types
  const allPropTypes = Object.keys(propTypes).reduce(
    (a, key) => Object.assign(a, propTypes[key]),
    {}
  )

  // props that are defined as propTypes are removed
  CleanEl.propTypes = {
    ...allPropTypes,
    ...customPropsTypes
  }

  return CleanEl
}

const display = responsiveStyle({
  prop: 'display',
  cssProperty: 'display'
})

const position = responsiveStyle({
  prop: 'position',
  cssProperty: 'position'
})

// TODO: having to temporarily add semicolons here due to this prettier reformatting bug
// to be removed once it's fixed
// https://github.com/prettier/prettier/issues/2291
export const Container = styled(BaseComponent())`
  box-sizing: border-box;
  ${props => props.mw && `max-width: ${props.mw}px;`}
  ${props =>
    props.maxWidth &&
    `max-width: ${
      props.noGutter
        ? props.theme.maxWidth + props.theme.space[3] * 2
        : props.theme.maxWidth
    }px; margin: auto;`}
  ${space}
  ${width}
  ${fontSize}
  ${color}
  ${textAlign}
  ${display}
  ${position}
`

// responsive flex box wrappers
export const Flex = styled(BaseComponent())`
  box-sizing: border-box;
  display: flex;
  ${flex}
  ${justifyContent}
  ${alignItems}
  ${flexWrap}
  ${flexDirection}
  ${space}
  ${width}
  ${fontSize}
  `

export const order = responsiveStyle('order')

export const Box = styled(BaseComponent())`
  box-sizing: border-box;
  ${flex}
  ${display}
  ${alignSelf}
  ${color}
  ${width}
  ${space}
  ${order}
  ${position}
`

// Link styles

export const NavLink = styled(NavigationLink)`
  text-decoration: none;
  display: block;
  color: ${props => props.theme.colors.text};
  .icon-active {
    display: none;
  }
  &.active,
  &:hover {
    .icon-active {
      display: flex;
    }
    .icon {
      display: none;
    }
  }
`

// Typography helpers

export const headers = elements => {
  return elements
    .map(
      (element, i) =>
        `${element} {
          font-size: ${theme.fontSizes[i]}px; 
          font-family: 'AvantGardeLT-Book'; 
        }`
    )
    .join('')
}

export const Title = styled.h1`
  font-family: ${props => props.theme.fonts.headers};
  font-weight: ${props => props.weight || '400'};
  ${space}
  ${width}
  ${fontSize}
  ${color}
`

export const MetaTitle = styled.h5`
  color: ${props => props.theme.colors.sharkGrey};
  text-transform: uppercase;
  font-family: ${props => props.theme.fonts.headers};
  letter-spacing: 1px;
  ${space}
  ${width}
  ${fontSize}
`

export const P = styled.p`
  font-family: 'AvantGardeLT-Book';
  ${space}
  ${width}
  ${fontSize}
  ${color}
`

export const Text = styled.p`
  font-family: 'AvantGardeLT-Book';
  ${space}
  ${width}
  ${fontSize}
  ${color}
`

export const breakpoints = {
  xs: `@media screen and (max-width: ${theme.breakpoints[0]})`,
  sm: `@media screen and (min-width: ${theme.breakpoints[0]})`,
  md: `@media screen and (min-width: ${theme.breakpoints[1]})`,
  lg: `@media screen and (min-width: ${theme.breakpoints[2]})`
}

export const hidden = key => props =>
  props[key]
    ? {
        [breakpoints[key]]: {
          display: 'none'
        }
      }
    : null

export const xs = hidden('xs')
export const sm = hidden('sm')
export const md = hidden('md')
export const lg = hidden('lg')

const Hide = styled.div([], xs, sm, md, lg)

export { Hide }

// Animations

export const slideDownFadeKeyframes = keyframes`
  0% {
    opacity: 0;
    top: -5px;
  }
  100% {
    opacity: 1;
    top: 0px;
  }
`

export const SlideDownFade = styled.div`
  position: relative;
  animation: ${slideDownFadeKeyframes} 1s cubic-bezier(0.4, 0, 0.2, 1) both;
`
